<template>
  <div>
    <div class="text-right flex">
      <button @click="handleDownload()" class="
                                      px-4
                                      py-2
                                      mb-4
                                      bg-gray-50
                                      border
                                      rounded-md
                                      text-balck
                                      flex
                                      items-center
                                    ">
        <img src="../../../assets/excel.png" alt="" />
        <span class="mx-3">Download</span>
      </button>

      <button @click="generatePdf()" class="
                                      px-4
                                      py-2
                                      mb-4
                                      ml-4
                                      bg-gray-50
                                      border
                                      rounded-md
                                      text-balck
                                      flex
                                      items-center
                                    ">
        <img class="h-5" src="../../../assets/pdf.png" alt="" />
        <span class="mx-3">Download</span>
      </button>
    </div>

    <div class="flex text-lg mb-4">
      <h1 class="pr-4 border-r uppercase ">
        <!-- {{ this.data[0].branch }} -->
      </h1>
      <h1 class="px-4 border-r">
        From:
        <span class="">{{
          $route.params.from | moment("ddd, MMM Do YYYY")
        }}</span>
      </h1>
      <h1 class="px-4">
        To:
        <span class="">{{
          $route.params.to | moment("ddd, MMM Do YYYY")
        }}</span>
      </h1>
    </div>
    <table id="my-table" class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>

          <th scope="col" class="
                                          px-6
                                          py-3
                                          text-xs
                                          font-medium
                                          text-gray-500
                                          uppercase
                                          tracking-wider
                                          text-left
                                        ">
            Sale range
          </th>

          <th scope="col" class="
                                          px-6
                                          py-3
                                          text-xs
                                          font-medium
                                          text-gray-500
                                          uppercase
                                          text-right
                                          tracking-wider
                                        ">
            Total Transactions
          </th>

          <th scope="col" class="
                                          px-6
                                          py-3
                                          text-xs
                                          font-medium
                                          text-right
                                          text-gray-500
                                          uppercase
                                          tracking-wider
                                        ">
            Total payment
          </th>

          <th scope="col" class="
                                          px-6
                                          py-3
                                          text-xs
                                          text-right
                                          font-medium
                                          text-gray-500
                                          uppercase
                                          tracking-wider
                                        ">
            Total fee
          </th>

        </tr>
      </thead>

      <tbody v-for="(value, i) in data" :key="i" class="bg-white divide-y divide-gray-200">
        <tr>

          <td class="
                                              px-6
                                              py-4
                 
                                              whitespace-nowrap
            
                                          ">
            <div class="text-sm text-gray-900">

              <b>{{ value.sales_range }}</b>
            </div>
          </td>

          <td class="
                                          px-6
                                          py-4
               
                                          whitespace-nowrap
                                          text-right
                                      ">
            <div class="text-sm  text-gray-900">

              <b>{{ value.total_transactions }}</b>
            </div>
          </td>

          <td class="
                                      px-6
                                      py-4
 
                                      whitespace-nowrap
                                      text-right
                                  ">
            <div class="text-sm  text-gray-900">
              GMD
              <b>{{ formatPrice(value.total_payment) }}</b>
            </div>
          </td>


          <td class="
                                  px-6
                                  py-4

                                  whitespace-nowrap
                                  text-right
                              ">
            <div class="text-sm  text-gray-900">
              GMD
              <b>{{ formatPrice(value.total_fee) }}</b>
            </div>
          </td>


        </tr>





        <!-- More people... -->
      </tbody>

      <tr v-if="data" class="bg-gray-50">
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            <div class="ml-4">
              <div class="text-sm font-medium text-gray-900">
                <b>TOTAL</b>
              </div>
            </div>
          </div>
        </td>


        <td class="px-6 py-4 whitespace-nowrap text-right">
          <div class="text-sm text-gray-900">
            <b>{{ totalTransactions() }}</b>
          </div>
        </td>


        <td class="px-6 py-4 whitespace-nowrap text-right">
          <div class="text-sm text-gray-900">
            GMD
            <b>{{ formatPrice(totalPayment()) }}</b>
          </div>
        </td>

        <td class="px-6 py-4 whitespace-nowrap text-right">
          <div class="text-sm text-gray-900">
            GMD
            <b>{{ formatPrice(totalFee()) }}</b>
          </div>
        </td>



      </tr>
    </table>
  </div>
</template>
  
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export default {
  props: ["data"],

  data: () => ({
    downloadLoading: false,

    filename: "",
    autoWidth: true,
    bookType: "xlsx",
  }),

  mounted() {
    console.log('test', this.data)
  },

  computed: {
    //   totalTransactions: function () {

    //     return    this.data.map(d => d.total_transactions).reduce((a , c) => a + c, 0)



    //   },

    //   totalPayment: function () {

    //     return    this.data.map(d => d.total_payment).reduce((a , c) => a + c, 0)

    //   },

    //   totalFee: function () {
    //     return    this.data.map(d => d.total_fee).reduce((a , c) => a + c, 0)



    //   },

    //   total: function () {
    //     let total = [];

    //     Object.entries(this.data).forEach(([key, val]) => {
    //       console.log(key);
    //       total.push(val.amount + val.commission); // the value of the current key.
    //     });

    //     return total.reduce(function (total, num) {
    //       return total + num;
    //     }, 0);
    //   },
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    generatePdf() {
      // var doc = new jsPDF();

      // console.log("DOC", doc);

      // var vm = this;

      let results = this.data;

      var result = Object.values(results);

      console.log("Results", result);

      // var docName = `Reconciliation Report: ${moment(vm.selected.date).format(
      //   "dddd, MMMM Do YYYY"
      // )}`;

      // var fileName = `reconciliation-report-${moment(vm.selected.date).format(
      //   "dddd, MMMM Do YYYY"
      // )}`;

      // var columns = [
      //   { title: "Branch Trans No", dataKey: "branch_transactions" },
      //   { title: "Agents Trans No", dataKey: "agent_transactions" },
      //   { title: "Total Trans No.", dataKey: "total_transactions" },
      //   { title: "Branch Sales", dataKey: "branch_sales" },
      //   { title: "Agents Sales", dataKey: "agent_sales" },
      //   { title: "Total Sales", dataKey: "total_sales" },
      // ];

      // var doc = new jsPDF("p", "pt");

      const doc = new jsPDF({ orientation: "l" });
      // require("jspdf-autotable");
      doc.text(
        `From: ${moment(this.$route.params.from).format(
          "ddd, MMM Do YYYY"
        )} | To: ${moment(this.$route.params.to).format("ddd, MMM Do YYYY")}`,
        10,
        20
      );

      // autoTable()

      // doc.setLineWidth(1.0);
      // doc.line(40, 30, 560, 30);

      // doc.setFontSize(12);
      // doc.text(
      //   `Reconcile By: ${
      //     vm.selected.created_by
      //   }    |   Total: GMD ${this.formatPrice(
      //     this.total(vm.selected.collection_reconciles)
      //   )}`,
      //   40,
      //   50
      // );

      // doc.setLineWidth(1.0);
      // doc.line(40, 60, 560, 60);

      // doc.setFontSize(12)    ;
      // doc.text(`Remarks: ${vm.selected.remarks}`, 40, 80);
      doc.autoTable({ html: "#my-table", margin: { top: 30 } });
      // doc.autoTable({
      //   columns: columns,
      //   body: result,
      //   margin: { top: 20 },
      // });

      doc.save(`${this.getFileName()}.pdf`);
    },

    totalTransactions() {

      return this.data.map(d => d.total_transactions).reduce((a, c) => a + c, 0)
    },

    totalPayment() {

      return this.data.map(d => d.total_payment).reduce((a, c) => a + c, 0)

    },

    totalFee() {
      return this.data.map(d => {
        console.log('d', d.total_fee);
        return parseInt(d.total_fee)
      }).reduce((a, c) => a + c, 0)



    },
    getTotalTransactions() {
      // this.data.map((trans) => console.log(trans.total_transactions));
      // console.log(this.data);
      // return this.data.map((trans) => trans.total_transactions);
      let result = 0;

      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        // console.log(`${key} ---- ${value.total_transactions}`);
        console.log(key);
        result += Number(value.total_transactions);
      }
      // console.log(result, "kkkkkkkkkkkk");
      return result;
    },
    getTotalBranchTransactions() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.branch_transactions);
      }
      return result;
    },
    getTotalAgentTransactions() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.agent_transactions);
      }
      return result;
    },
    getTotalBranchSales() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.branch_sales);
      }
      return result;
    },
    getTotalAgentSales() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.agent_sales);
      }
      return result;
    },
    getTotalSales() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.total_sales);
      }
      return result;
    },
    getTotalBranchCommission() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.branch_commission);
      }
      return result;
    },
    getTotalAgentCommission() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.agent_commission);
      }
      return result;
    },
    getTotalCommission() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.total_commission);
      }
      return result;
    },

    getFileName() {
      return (
        this.$route.params.reportOn +
        "-" +
        this.$route.params.from +
        "-" +
        this.$route.params.to
      );
    },

    handleDownload() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "Date",
          "Branch Trans No.",
          "Agent Trans No.",
          "Total Trans No.",
          "Branch Sales",
          "Agent Sales",
          "Total Sales",
          "Branch Commission",
          "Agent Commission",
          "Total Commission",
        ];
        const filterVal = [
          "date",
          "branch_transactions",
          "agent_transactions",
          "total_transactions",
          "branch_sales",
          "agent_sales",
          "total_sales",
          "branch_commission",
          "agent_commission",
          "total_commission",
        ];
        const list = this.data;
        console.log("LIST", list);
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.getFileName(),
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });
    },

    formatJson(filterVal, jsonData) {
      console.log(jsonData);
      return Object.keys(jsonData).map((v) => {
        console.log();
        return filterVal.map((j) => {
          console.log("J", j);
          console.log();

          if (j === "date") {
            return v;
          } else if (j === "total") {
            return (
              Number(jsonData[v]["amount"]) + Number(jsonData[v]["commission"])
            );
          } else {
            return jsonData[v][j];
          }
        });
      });
    },
  },
};
</script>
  